import { useState, useEffect, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useTranslations } from 'next-intl';
import TimePicker from './timepicker';
import Button from '@mui/material/Button';
import OutsideAlerter from 'components/outsite';



export default function Main({ onchangeTimer, timepickerDefault: _timepickerDefault, notitle = false, am_pm = true }) {
    const t = useTranslations();


    const [selected, setSelected] = useState('am');
    const [opened, setOpened] = useState(false);
    const [timepickerDefault, setTimepickerDefault] = useState(_timepickerDefault ? _timepickerDefault : {
        hours: '00',
        minutes: '00',
        seconds: '00'
    });
    const [time, setTime] = useState('');

    useEffect(() => {
        setTime(`${Object.values(timepickerDefault).map(i => i.toString().length < 2 ? `0${i}` : i).join(':')} ${am_pm ? selected : ""}`)


    }, [selected, timepickerDefault]);

    const handleTime = () => {

    };

    const handleClickOutside = () => {
        console.log("===handleClickOutside===");
        // setOpened(false)
    };

    // const ref = useDetectClickOutside({ onTriggered: handleClickOutside, disableClick: false });
    //const ref = useOutsideClick(handleClickOutside);


    const timepickerCb = (tpdata) => {

        setTimepickerDefault({
            hours: tpdata.hours?.value || 0,
            minutes: tpdata.minutes?.value || 0,
            seconds: tpdata.seconds?.value || 0
        });
        console.log("===tpdata===", tpdata);
        onchangeTimer({
            hours: tpdata.hours?.value || 0,
            minutes: tpdata.minutes?.value || 0,
            seconds: tpdata.seconds?.value || 0,
            format: selected

        });
        // setTime(`${Object.values(tpdata).map(i => i.value.toString().length < 2 ? `0${i.value}` : i.value).join(':')} ${selected}`);

    };



    return (<>
        <OutsideAlerter cbOutSide={() => { setOpened(false) }}>


            <FormControl style={{ paddingLeft: '0' }} className='flex-form w-100 custom-timepicker' variant="standard"  >
                {!notitle && <div className='title'>
                    <h5>{t('time')}</h5>
                </div>}
                <div className='field extra-field' >
                    <TextField
                        className="text-input text-input-timer"
                        value={time}
                        disabled
                        onChange={handleTime}
                        InputProps={{
                            endAdornment: (
                                <div className='timer-icon circle' onClick={() => { setOpened(!opened) }}>
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.5 9.91663C16.4166 9.91663 16.25 9.99996 16.1666 10.0833L15.3333 10.9166L17.0833 12.6666L17.9166 11.8333C18.0833 11.6666 18.0833 11.3333 17.9166 11.1666L16.8333 10.0833C16.75 9.99996 16.6666 9.91663 16.5 9.91663ZM14.9166 11.4166L9.83329 16.4166V18.1666H11.5833L16.6666 13.0833L14.9166 11.4166ZM9.41663 4.83329V9.16663L12.75 11.1666L11.9166 12L8.16663 9.83329V4.83329H9.41663ZM8.16663 17.25C3.91663 16.8333 0.666626 13.25 0.666626 8.99996C0.666626 4.41663 4.41663 0.666626 8.99996 0.666626C13.4166 0.666626 17 4.08329 17.3333 8.41663C17.0833 8.33329 16.8333 8.24996 16.5 8.24996C16.1666 8.24996 15.9166 8.33329 15.6666 8.41663C15.3333 4.99996 12.5 2.33329 8.99996 2.33329C5.33329 2.33329 2.33329 5.33329 2.33329 8.99996C2.33329 12.4166 4.91663 15.25 8.24996 15.5833L8.16663 15.75V17.25Z" fill="#444D67" />
                                    </svg>
                                </div>
                            )
                        }}

                    />

                </div>
                {opened && <div className="dropDown-div" >
                    <div className="popover-arrow"></div>
                    {am_pm && <div className='am-pm'>
                        <div className='am-pm-group'>
                            <Button className={selected === 'am' ? "amButton selected-button" : "amButton"} onClick={() => {
                                setSelected('am'); onchangeTimer({
                                    ...timepickerDefault,
                                    format: 'am'

                                });
                            }}>{t('am')}</Button>
                            <Button className={selected === 'pm' ? "pmButton selected-button" : "pmButton"} onClick={() => {
                                setSelected('pm'); onchangeTimer({
                                    ...timepickerDefault,
                                    format: 'pm'

                                });
                            }}>{t('pm')}</Button>
                        </div>
                    </div>}
                    <div className='times'>
                        <TimePicker data={timepickerDefault} onChange={timepickerCb} />
                    </div>
                    <div className='dropDown-operations'>
                        <div onClick={() => { setOpened(false) }} className='cancel'>{t('Cancel')}</div>
                        <div onClick={() => { setOpened(false) }} className='ok'>{t('Save')}</div>
                    </div>
                </div>}
            </FormControl>
        </OutsideAlerter>
    </>)

}