import { useState } from "react";
import Select, { components } from "react-select";

import FormControl from '@mui/material/FormControl';
import { useTranslations } from 'next-intl';

export default function Main({ onChange = () => { }, data = {} }) {
    const t = useTranslations();
    console.log("====data-======", data);
    const default_value = { label: '00', value: 0 };
    const [hours, setHours] = useState(data.hours ? {
        label: data.hours.toString().length < 2 ? `0${data.hours}` : data.hours,
        value: data.hours
    } : false);
    const [minutes, setMinutes] = useState(data.minutes ? {
        label: data.minutes.toString().length < 2 ? `0${data.minutes}` : data.minutes,
        value: data.minutes
    } : false);
    const [seconds, setSeconds] = useState(data.seconds ? {
        label: data.seconds.toString().length < 2 ? `0${data.seconds}` : data.seconds,
        value: data.seconds
    } : false);

    const d_minutes = [...Array(60)].map((_, i) => {
        if (i === 0) return null;
        return {
            label: i.toString().length < 2 ? `0${i}` : i,
            value: i
        }

    }).filter(Boolean);


    const d_hours = [...Array(24)].map((_, i) => {
        if (i === 0) return null;
        return {
            label: i.toString().length < 2 ? `0${i}` : i,
            value: i
        }

    }).filter(Boolean);
    const d_seconds = [...Array(60)].map((_, i) => {
        if (i === 0) return null;
        return {
            label: i.toString().length < 2 ? `0${i}` : i,
            value: i
        }

    }).filter(Boolean);


    const ClearInd = () => (<svg style={{ cursor: "pointer" }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z" fill="#444D67" />
    </svg>);
    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span className="custom-css-class">{t('key_not_found')}</span>
            </components.NoOptionsMessage>
        );
    };
    const ClearIndicator = (props) => {
        const {
            children = <ClearInd />,
            getStyles,
            innerProps: { ref, ...restInnerProps },
        } = props;
        return (
            <div
                {...restInnerProps}
                ref={ref}
                style={getStyles('clearIndicator', props)}
            >
                <div style={{ padding: '0px 5px' }}>{children}</div>
            </div>
        );
    };
    const DropdownIndicator = props => {
        if (!props.hasValue && !props.selectProps.menuIsOpen) {
            return (
                <components.DropdownIndicator {...props} >
                    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0L5 5L10 0H0Z" fill="#152141" />
                    </svg>
                </components.DropdownIndicator>

            )
        }
        if (!props.hasValue && props.selectProps.menuIsOpen) {
            return (
                <components.DropdownIndicator {...props} >
                    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 5L5 -4.37114e-07L0 5L10 5Z" fill="#444D67" />
                    </svg>
                </components.DropdownIndicator>
            )
        }
        return null

    };

    return (
        <>
            <FormControl>
                <div className='title for-timer-inner-title'>
                    <h5>{t('hours')}</h5>
                </div>
                <Select
                    labelId="demo-simple-select-label"
                    className={"simple-select"}
                    classNamePrefix="simple-select"
                    placeholder={'00'}
                    id={"simple-select-id"}
                    value={hours}

                    components={{
                        NoOptionsMessage,
                        ClearIndicator,
                        DropdownIndicator,
                        IndicatorSeparator: () => null
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'rgb(3, 201, 215)',
                            primary: 'rgb(3, 201, 215)',
                        },
                    })}

                    options={d_hours}
                    isClearable={true}
                    onError={() => { console.log("error") }}
                    onErrorCapture={() => { console.log("error") }}
                    onKeyDown={(params) => { }}
                    onChange={(e) => {
                        setHours(e);
                        onChange({
                            hours: e,
                            minutes: minutes || default_value,
                            seconds: seconds || default_value
                        });

                    }}

                    styles={{
                        noOptionsMessage: base => {

                            return { ...base, color: "red" }
                        },

                        menuList: (base) => ({
                            ...base,
                            "::-webkit-scrollbar-track": {
                                background: "white"
                            },
                            "::-webkit-scrollbar-thumb": {
                                background: "#60B475"
                            },
                            "::-webkit-scrollbar-thumb:hover": {
                                background: "#60B475"
                            }
                        })
                    }}
                >

                </Select>
            </FormControl>
            <FormControl>
                <div className='title for-timer-inner-title'>
                    <h5>{t('minutes')}</h5>
                </div>
                <Select
                    labelId="demo-simple-select-label"
                    className={"simple-select"}
                    classNamePrefix="simple-select"
                    placeholder={'00'}
                    id={"simple-select-id1"}
                    value={minutes}
                    components={{
                        NoOptionsMessage,
                        ClearIndicator,
                        DropdownIndicator,
                        IndicatorSeparator: () => null
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'rgb(3, 201, 215)',
                            primary: 'rgb(3, 201, 215)',
                        },
                    })}

                    options={d_minutes}
                    isClearable={true}
                    onError={() => { console.log("error") }}
                    onErrorCapture={() => { console.log("error") }}
                    onKeyDown={(params) => { }}
                    onChange={(e) => {
                        setMinutes(e);
                        onChange({
                            hours: hours || default_value,
                            minutes: e,
                            seconds: seconds || default_value
                        });
                    }}

                    styles={{
                        noOptionsMessage: base => {

                            return { ...base, color: "red" }
                        },

                        menuList: (base) => ({
                            ...base,
                            "::-webkit-scrollbar-track": {
                                background: "white"
                            },
                            "::-webkit-scrollbar-thumb": {
                                background: "#60B475"
                            },
                            "::-webkit-scrollbar-thumb:hover": {
                                background: "#60B475"
                            }
                        })
                    }}
                >

                </Select>
            </FormControl>
            <FormControl>
                <div className='title for-timer-inner-title'>
                    <h5>{t('seconds')}</h5>
                </div>
                <Select
                    labelId="demo-simple-select-label"
                    className={"simple-select"}
                    classNamePrefix="simple-select"
                    placeholder={'00'}
                    id={"simple-select-id2"}
                    value={seconds}
                    components={{
                        NoOptionsMessage,
                        ClearIndicator,
                        DropdownIndicator,
                        IndicatorSeparator: () => null
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'rgb(3, 201, 215)',
                            primary: 'rgb(3, 201, 215)',
                        },
                    })}

                    options={d_seconds}
                    isClearable={true}
                    onError={() => { console.log("error") }}
                    onErrorCapture={() => { console.log("error") }}
                    onKeyDown={(params) => { }}
                    onChange={(e) => {
                        setSeconds(e);
                        onChange({
                            hours: hours || default_value,
                            minutes: minutes || default_value,
                            seconds: e
                        });
                    }}

                    styles={{
                        noOptionsMessage: base => {

                            return { ...base, color: "red" }
                        },

                        menuList: (base) => ({
                            ...base,
                            "::-webkit-scrollbar-track": {
                                background: "white"
                            },
                            "::-webkit-scrollbar-thumb": {
                                background: "#60B475"
                            },
                            "::-webkit-scrollbar-thumb:hover": {
                                background: "#60B475"
                            }
                        })
                    }}
                >

                </Select>
            </FormControl>
        </>
    )
}