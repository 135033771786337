
import React, { useState, useEffect } from "react";
import moment from "moment";
import Button from '@mui/material/Button';
import Clock from 'react-live-clock';
import AddAlarm from './add';
import Display from "./display";
import Faq from '../faq';
import Rating from '../rating';
import { useLocalStorage } from 'hooks/localstorage';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { alarmsState } from 'store/atoms';
import useSound from 'use-sound';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import AccessAlarmSharpIcon from '@mui/icons-material/AccessAlarmSharp';
import LanguageIcon from '@mui/icons-material/Language';


import { useTranslations } from 'next-intl';






export default function Alarm({ lang_code, page_content, embed = false, faq = [] }) {

    const [datetime, setDatetime] = useState(null);
    const [alarm, setAlarm] = useLocalStorage("alarm", null);
    const alarms = useRecoilValue(alarmsState);
    const setAlarms = useSetRecoilState(alarmsState);
    const [lsalarms, setLsalarms] = useLocalStorage("alarms");
    const [open, setOpen] = React.useState(false);
    const [openAlarm, setOpenAlarm] = React.useState(false);
    const t = useTranslations();
    const [play, { stop }] = useSound('/iphone_alarm.mp3');
    const [mounted, setMounted] = useState(false);
    const [alarmremoved, setAlarmremoved] = useState(false);

    useEffect(() => {
        setMounted(true);
        console.log("useEffect alarms", alarms);
        console.log("useEffect lsalarms", lsalarms);
        if (alarms.length === 0 && lsalarms && lsalarms.length > 0) {
            setAlarms([...lsalarms]);
        }

    }, []);

    const closeAlarmModal = () => {
        setOpenAlarm(false);
    };
    const stopAlarm = () => {
        stop();
        setAlarm(null);
        closeAlarmModal();
    }
    const removeAlarm = (index) => {
        let new_list = [...alarms];
        new_list.splice(index, 1);
        setAlarms([...new_list]);
        localStorage.setItem('alarms', JSON.stringify([...new_list]));
        setAlarmremoved(true);
    };


    const alarm_info = [{
        icon: (<LanguageIcon sx={{
            fontSize: "43px"
        }} />
        ),
        text: t('key_alarm_info_1')
    }, {
        icon: (<LanguageIcon sx={{
            fontSize: "43px"
        }} />),
        text: t('key_alarm_info_2')
    }, {
        icon: (<LanguageIcon sx={{
            fontSize: "43px"
        }} />),
        text: t('key_alarm_info_3')
    }]





    return (
        <>
            {<div className="today-div">
                {mounted && <Clock className="digital_clock_style" format={'HH:mm:ssa'} ticking={true} />}
                <h2 className="digital_clock_style_date"> {t(moment().format('dddd'))} / {t(moment().format("MMMM"))}&nbsp;{moment().format("D")} / {`${moment().format('Y')}`}</h2>
            </div>}

            {alarms && alarms.length > 0 && <div className="my-container extra-for-display">
                {alarms.map((v, i) => {
                    return (<Display key={i} removeItem={removeAlarm} index={i} alarm={v} />)
                })}
            </div>}
            <AddAlarm />


            {!embed && <>    <div className="ad-container my-container small-ad">
                <div className="ad-text">980x120<br />ad</div>
            </div>
                <div className="my-container info-block m-top-40">
                    {alarm_info.map((v, i) => {
                        return (<div key={i} className="info-block-each">
                            <div className="info-block-each-icon">{(i + 1)})</div>
                            <div className="info-block-each-text">{v.text}</div>
                        </div>)
                    })}
                </div>
                <div className="ad-container my-container md-ad">
                    <div className="ad-text">930x180<br />ad</div>
                </div>
                <div className='my-container m-top-28 page-content-coantainer' dangerouslySetInnerHTML={{ __html: page_content.replace('TimerTone', `<a name="timertone" href="//${lang_code}.timertone.${process.env.NEXT_PUBLIC_DOMAIN_SUFFIX}/dashboard/alarm">TimerTone</a>`) }}>

                </div>
                {<Faq faq={faq} from={'alarm'} lang_code={lang_code} />}

                <div className="ad-container my-container bg-ad">
                    <div className="ad-text">980 x 250<br />ad</div>
                </div>

            </>
            }
            <Dialog open={openAlarm} onClose={closeAlarmModal}>
                <DialogTitle>{t('Alarm')}</DialogTitle>
                <DialogContent className="m-w-250">
                    <AccessAlarmSharpIcon fontSize="large" color="error" />
                    {datetime && alarm && <Clock className="digital_clock_style" date={alarm} format={'HH:mm:ss'} ticking={false} />}

                </DialogContent>
                <DialogActions>
                    <Button onClick={stopAlarm}>{t('Ok')}</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={alarmremoved}
                autoHideDuration={5000}
                message={t('Alarm removed')}
                onClose={() => setAlarmremoved(false)}
            />
        </>

    )

}


