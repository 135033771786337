import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';



import FormControl from '@mui/material/FormControl';
import { useLocalStorage } from 'hooks/localstorage';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { alarmsState, embedAlarmState } from 'store/atoms';
import Button from '@mui/material/Button';
import TimePicker from 'components/otimepicker/withdropdown';

//import Select from '@mui/material/Select';
import Select, { components } from "react-select";
import { useTranslations } from 'next-intl';


import Snackbar from '@mui/material/Snackbar';
import { createTheme } from "@mui/material/styles";



const themeBlue = createTheme({ palette: { primary: { main: "rgb(3, 201, 215)", secondary: "white" } } });

const daysOfWeek = [{
    id: 1,
    name: "mon"
}, {
    id: 2,
    name: "tue"
}, {
    id: 3,
    name: "wed"
}, {
    id: 4,
    name: "thu"
}, {
    id: 5,
    name: "fri"
}, {
    id: 6,
    name: "sat"
}, {
    id: 0,
    name: "sun"
}];
const sounds = [{
    value: 'alarm_fast_high.mp3',
    label: 'Fast high',

}, {
    value: 'iphone_alarm.mp3',
    label: 'Alarm',
}]

const ClearInd = () => (<svg style={{ cursor: "pointer" }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z" fill="#444D67" />
</svg>);
const ClearIndicator = (props) => {
    const {
        children = <ClearInd />,
        getStyles,
        innerProps: { ref, ...restInnerProps },
    } = props;
    return (
        <div
            {...restInnerProps}
            ref={ref}
            style={getStyles('clearIndicator', props)}
        >
            <div style={{ padding: '0px 5px' }}>{children}</div>
        </div>
    );
};





const DropdownIndicator = props => {
    if (!props.hasValue && !props.selectProps.menuIsOpen) {
        return (
            <components.DropdownIndicator {...props} >
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0L5 5L10 0H0Z" fill="#152141" />
                </svg>
            </components.DropdownIndicator>

        )
    }
    if (!props.hasValue && props.selectProps.menuIsOpen) {
        return (
            <components.DropdownIndicator {...props} >
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 5L5 -4.37114e-07L0 5L10 5Z" fill="#444D67" />
                </svg>
            </components.DropdownIndicator>
        )
    }
    return null

};



export default function Main({ embed = false }) {
    const [time, setTime] = useState(+new Date());
    const [duration, setDuration] = useState(0);
    const [title, setTitle] = useState('');
    const [openmore, setOpenmore] = useState(true);
    const [playing, setPlaying] = useState({});
    const [previewAudio, setPreviewAudio] = useState(null);
    const [days, setDays] = useState(null);//React.useState(() => ['bold', 'italic']);
    const [sound, setSound] = useState(null);//React.useState(() => ['bold', 'italic']);
    const alarms = useRecoilValue(alarmsState);
    const setAlarms = useSetRecoilState(alarmsState);
    const embedAlarm = useRecoilValue(embedAlarmState);
    const setEmbedAlarm = useSetRecoilState(embedAlarmState);
    const [lsalarms, setLsalarms] = useLocalStorage("alarms");
    const [timepickerDefault, setTimepickerDefault] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0
    });
    const [alarmset, setAlarmset] = useState(false);


    const t = useTranslations();
    //let previewAudio;
    const snoozeOptions = [...Array(60)].map((_, i) => {
        return {
            label: i.toString().length < 2 ? `0${i}` : i,
            value: i
        }

    })





    const handleDays = (event, newDays) => {
        setDays(newDays);
    };
    const handleSound = (sound) => {
        setSound(sound);
    };
    const handleDuration = (e) => {

        setDuration(e);
    }
    const handleTitle = (e) => {
        setTitle(e.target.value);
    }
    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span className="custom-css-class">{t('key_not_found')}</span>
            </components.NoOptionsMessage>
        );
    };

    const setAlarm = () => {


        const alarm = {
            time: {
                ...timepickerDefault
            },
            snooze: duration,
            title: title,
            days: days,
            sound: sound
        };

        if (!embed) {
            setAlarms([alarm, ...(alarms ? alarms : [])]);
            setLsalarms([alarm, ...(alarms ? alarms : [])]);
            setAlarmset(true);
        } else {
            setEmbedAlarm({ ...alarm });
            localStorage.setItem('embed_alarm', JSON.stringify({ ...alarm }));

        }

    }

    const handleClose = (event, reason) => {
        setAlarmset(false);
    };

    const handlePlay = (audio = null, action = 'play', index) => {
        try {
            previewAudio = audio ? audio : previewAudio;
            // const audio = new Audio('/iphone_alarm.mp3');
            if (action === 'play' && previewAudio) {
                setPlaying({
                    ...playing,
                    [index]: true
                })
                setPreviewAudio(audio);
                previewAudio.play();

            }

            if (action === 'stop' && previewAudio) {
                setPlaying({
                    ...playing,
                    [index]: false
                })
                previewAudio.pause();

            }



        } catch (error) {

        }
    }

    const timepickerCb = (tpdata) => {

        setTimepickerDefault({
            hours: tpdata.hours || 0,
            minutes: tpdata.minutes || 0,
            seconds: tpdata.seconds || 0,
            format: tpdata.format || 'am',
        });


    };
    //defaultMenuIsOpen={true}
    return (<>
        <div className={embed ? 'addAlarm embedAddAlarm' : 'addAlarm'}>
            <h2>{t('Set new alarm')}</h2>
            <FormControl className=' flex-form' variant="standard">
                <div className='title'>
                    <h3>{t('Set a title')}</h3>
                </div>
                <div className='field'>
                    <TextField

                        className="text-input"
                        value={title}
                        onChange={handleTitle}
                    />
                </div>
            </FormControl>
            <FormControl className='flex-form timepicker-div' variant="standard">
                <div className='title'>
                    <h3>{t('Set a time')}</h3>
                </div>

                <div className='field'>
                    <TimePicker notitle={true} timepickerDefault={timepickerDefault} onchangeTimer={timepickerCb} />
                </div>
            </FormControl>
            {openmore && <>
                {/*<FormControl className=' flex-form' variant="standard">
                <div className='title'>
                    <h3>{t('Choose a day of the week')}</h3>
                </div>

                <div className='field'>
                    <ToggleButtonGroup
                        value={days}
                        onChange={handleDays}
                        aria-label="text formatting"
                    >
                        {daysOfWeek.map((v, i) => {
                            return (<ToggleButton value={v.name} key={i} aria-label={v.name}>
                                {v.name}
                            </ToggleButton>)
                        })}


                    </ToggleButtonGroup>
                </div>
                    </FormControl>*/}

                <FormControl className=' flex-form' variant="standard">
                    <div className='title'>
                        <h3>{t('Choose a sound')}</h3>
                    </div>
                    <div className='field'>
                        <FormControl className='sound-container'>
                            <Select
                                labelId="demo-simple-select-label"
                                className={"simple-select"}
                                classNamePrefix="simple-select"
                                placeholder={t('Choose a sound')}
                                id={"simple-select-id"}
                                instanceId={"simple-select-id"}
                                value={sound}
                                components={{
                                    NoOptionsMessage,
                                    ClearIndicator,
                                    DropdownIndicator,
                                    IndicatorSeparator: () => null
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'rgb(3, 201, 215)',
                                        primary: 'rgb(3, 201, 215)',
                                    },
                                })}

                                options={sounds}
                                isClearable={true}
                                onError={() => { console.log("error") }}
                                onErrorCapture={() => { console.log("error") }}
                                onKeyDown={(params) => { console.log("==parmas===", params) }}
                                onChange={handleSound}

                                styles={{
                                    noOptionsMessage: base => {

                                        return { ...base, color: "red" }
                                    },

                                    menuList: (base) => ({
                                        ...base,
                                        "::-webkit-scrollbar-track": {
                                            background: "white"
                                        },
                                        "::-webkit-scrollbar-thumb": {
                                            background: "#60B475"
                                        },
                                        "::-webkit-scrollbar-thumb:hover": {
                                            background: "#60B475"
                                        }
                                    })
                                }}
                            >

                            </Select>
                            {sound && <div className='sound-buttons'>
                                {!playing[sound.value] && <div onClick={() => { handlePlay(new Audio(`/` + sound.value), 'play', sound.value) }} className='play-button operation-button'>
                                    <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM8 14.5L14 10L8 5.5V14.5Z" fill="#E8E9EC" />
                                    </svg>
                                </div>}
                                {playing[sound.value] && <div onClick={() => { handlePlay(null, 'stop', sound.value) }} className='stop-button operation-button'>
                                    <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2ZM7 7V13H13V7" fill="#E8E9EC" />
                                    </svg>
                                </div>}
                            </div>}
                        </FormControl>
                    </div>
                </FormControl>
                <FormControl className=' flex-form' variant="standard">
                    <div className='title'>
                        <h3>{t('Snooze duration')}</h3>
                    </div>
                    <div className='field'>
                        <FormControl>
                            <Select
                                labelId="demo-simple-select-label"
                                className={"simple-select"}
                                classNamePrefix="simple-select"
                                placeholder={'00'}
                                id={"simple-select-id-id"}
                                instanceId={"simple-select-id-id"}
                                value={duration}
                                components={{
                                    NoOptionsMessage,
                                    ClearIndicator,
                                    DropdownIndicator,
                                    IndicatorSeparator: () => null
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'rgb(3, 201, 215)',
                                        primary: 'rgb(3, 201, 215)',
                                    },
                                })}

                                options={snoozeOptions}
                                isClearable={true}
                                onError={() => { console.log("error") }}
                                onErrorCapture={() => { console.log("error") }}
                                onKeyDown={(params) => { console.log("==parmas===", params) }}
                                onChange={handleDuration}

                                styles={{
                                    noOptionsMessage: base => {

                                        return { ...base, color: "red" }
                                    },

                                    menuList: (base) => ({
                                        ...base,
                                        "::-webkit-scrollbar-track": {
                                            background: "white"
                                        },
                                        "::-webkit-scrollbar-thumb": {
                                            background: "#60B475"
                                        },
                                        "::-webkit-scrollbar-thumb:hover": {
                                            background: "#60B475"
                                        }
                                    })
                                }}
                            >

                            </Select>
                        </FormControl>
                    </div>
                </FormControl></>}
            <FormControl className='flex-form' variant="standard">
                <div className='title'></div>
                <div className='field key_set_alarm '>
                    <div className="btn-div">
                        <Button variant="contained" onClick={setAlarm}  >
                            {t('Set Alarm')}
                        </Button>
                    </div>
                </div>
            </FormControl>

            <Snackbar
                open={alarmset}
                autoHideDuration={5000}
                message={t('Alarm set!')}
                onClose={handleClose}
            />

        </div >
    </>)
}