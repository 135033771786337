import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" {...props} />
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({ value, id }) {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        if (value == 0) return;
        const timer = setInterval(() => {
            //    console.log("=======value id=====", `${value}   `, id)

            setProgress((prevProgress) => {
                //   console.log("=====prevProgress====", prevProgress);
                if (prevProgress >= 100) {
                    return 0;
                }
                return prevProgress + ((100 * 1000) / value);

            });
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel value={progress} />
        </Box>
    );
}
export function LinearWithValueLabelSimple({ value, total }) {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        setProgress(() => {

            if (progress >= 100) {
                return 0;
            }
            if (value <= 0) {
                return 0;
            }
            return Math.abs(((100 * value) / total) - 100);

        });

        return () => {
            setProgress(0);
        };
    }, [value]);

    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel value={progress} />
        </Box>
    );
}
