import React, { useState, useEffect } from "react";
import moment from "moment";
import { experimentalStyled as styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import Paper from '@mui/material/Paper';
import Clock from 'react-live-clock';

import useSound from 'use-sound';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

import LinearWithValueLabel from 'components/progress';
import Countdown from 'react-countdown';
import { RRule } from 'rrule'
import Switch from "react-switch";

import Image from "next/image";
import Snackbar from '@mui/material/Snackbar';



import { useTranslations } from 'next-intl';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    minHeight: "220px",
    width: "491px",
    textAlign: "center",
    position: "relative",
    color: theme.palette.text.secondary,
}));


const daysToRules = {
    'mon': RRule.MO,
    'tue': RRule.TU,
    'wed': RRule.WE,
    'thu': RRule.TH,
    'fri': RRule.FR,
    'sat': RRule.SA,
    'sun': RRule.SU
};


// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed, props }) => {
    // Render a countdown
    return <div className="countdown-div">{days ? `${days} days and ` : ''} {hours.toString().length < 2 ? `0${hours}` : hours}:{minutes.toString().length < 2 ? `0${minutes}` : minutes}:{seconds.toString().length < 2 ? `0${seconds}` : seconds} {props.dday ? `, ${props.dday}` : ''}</div>;

};

export default function Main({ index, alarm: alarmObj, removeItem, embed = false }) {
    console.log("===alarmObj==", alarmObj);
    let defaultAlarm = moment().set({
        hour: alarmObj.time.hours,
        minute: alarmObj.time.minutes,
        seconds: alarmObj.time.seconds,
    });
    if (defaultAlarm.diff(moment()) < 0) {
        defaultAlarm.add('1', 'day');
    }


    let alarmTime = defaultAlarm;
    const sound = alarmObj.sound ? alarmObj.sound : 'iphone_alarm.mp3';

    const days = alarmObj.days;
    const snooze = alarmObj.snooze;





    const [switcher, setSwitcher] = useState(true);
    const [alarm, setAlarm] = useState(defaultAlarm)// useLocalStorage("alarm", null);
    const [openAlarm, setOpenAlarm] = React.useState(false);
    const t = useTranslations();
    const [play, { stop }] = useSound(`/${sound}`);

    const [openRemove, setOpenRemove] = useState(false)



    useEffect(() => {

        //Need to check if time passed or no andhas recurring alarm or no
        if (alarmTime.valueOf() <= moment().valueOf() && days && days.length > 0) {
            // Create a rule:
            const rule = new RRule({
                freq: RRule.WEEKLY,
                interval: 1,
                byweekday: [...days.map(v => daysToRules[v])],
                dtstart: new Date(alarmTime.valueOf()),
                until: moment().add(7, 'days')
            });
            const rules = rule.all();
            if (moment(rules['0']).valueOf() <= moment().valueOf()) {
                alarmTime = moment(rules['1']);
            } else {
                alarmTime = moment(rules['0']);
            }

        }
        setAlarm(alarmTime);
    }, [alarmObj]);


    const openAlarmModal = (e) => {
        setOpenAlarm(true);
    };

    const closeAlarmModal = () => {
        setOpenAlarm(false);
    };

    const closeTrashDialog = () => {
        setOpenRemove(false);
    };


    const stopAlarm = () => {
        stop();
        setAlarm(defaultAlarm);
        setSwitcher(false);
        closeAlarmModal();
    }

    const snoozeAlarm = () => {
        stop();
        setAlarm(alarm.add(snooze, 'minute'));
        setSwitcher(true);
        closeAlarmModal();
    }



    const raiseAlarm = () => {
        openAlarmModal();
        play();
    }

    const handleSwitch = (switcher) => {

        setSwitcher(switcher);
    }
    const uncheckedIcon = (
        <span className="switcher-false">{t('Off')}</span>
    );

    const checkedIcon = (
        <span className="switcher-true">{t('On')}</span>
    );

    const removeAlarm = () => {
        setOpenRemove(true)
    }


    return (
        <>
            <Item className={embed ? `alarm-box embed-display` : `alarm-box `}>
                <h4>{alarmObj.title}</h4>
                <div className="flex-div">
                    <div className="time">
                        {alarmTime.format('HH:mm a')}
                    </div>
                    <div onClick={removeAlarm} className="remove-button circle">
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.50004 0.5V1.33333H0.333374V3H1.16671V13.8333C1.16671 14.2754 1.3423 14.6993 1.65486 15.0118C1.96742 15.3244 2.39135 15.5 2.83337 15.5H11.1667C11.6087 15.5 12.0327 15.3244 12.3452 15.0118C12.6578 14.6993 12.8334 14.2754 12.8334 13.8333V3H13.6667V1.33333H9.50004V0.5H4.50004ZM2.83337 3H11.1667V13.8333H2.83337V3ZM4.50004 4.66667V12.1667H6.16671V4.66667H4.50004ZM7.83337 4.66667V12.1667H9.50004V4.66667H7.83337Z" fill="#8A90A0" />
                        </svg>
                    </div>
                </div>
                <div className="progress-bar">
                    {switcher && alarm.diff(moment()) > 0 && <div className="progress-bar-info">
                        <Countdown
                            daysInHours={false}
                            onComplete={() => raiseAlarm()}
                            dday={t(alarm.format('dddd'))}
                            className="digital_clock_style" date={alarm.format()}
                            renderer={renderer}
                        />
                        <LinearWithValueLabel value={alarm.diff(moment())} />
                        <div className="days">
                            {alarmObj.days && alarmObj.days.join(', ')}
                        </div>
                    </div>
                    }
                    <div className="switcher-main">
                        <Switch className="my-switcher" offColor={'#B9BCC6'} onColor={'#03C9D7'} handleDiameter={16} width={58} height={22} borderRadius={30} checkedIcon={checkedIcon} uncheckedIcon={uncheckedIcon} onChange={handleSwitch} checked={switcher} />


                    </div>
                </div>
            </Item>
            <Dialog className="trashDialog" open={openRemove} onClose={closeTrashDialog}>
                <DialogContent>

                    <div className="message-text">
                        {t("Are you sure you want to delete this alarm?")}
                    </div>
                    <DialogActions>
                        <Button className="cancelButton" onClick={closeTrashDialog}>{t('Cancel')}</Button>
                        <Button className="deleteButton" onClick={() => { removeItem(index); setOpenRemove(false); }}>{t('Delete')}</Button>
                    </DialogActions>
                </DialogContent>

            </Dialog>

            <Dialog className="alarmDialog" open={openAlarm} onClose={closeAlarmModal}>
                <div onClick={stopAlarm} className="removeSign">
                    <Image alt='remove' width="14px" height="14px" src={'/images/remove-sign.svg'} />
                </div>
                <DialogTitle>{t('key_alarm')}</DialogTitle>
                <DialogContent className="m-w-250">
                    <div className="alarm-clock">
                        <Image alt='alarm' width={'48px'} height={'48px'} src={'/images/icon_clock_black.svg'} />

                    </div>

                    {alarm && <Clock className="digital_clock_style extra-style-clock" date={alarm.valueOf()} format={'HH:mm:ss'} ticking={false} />}
                    <DialogActions>
                        <div className="btn-div">
                            <Button onClick={stopAlarm} variant="contained" startIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2.3125C10.6868 2.3125 9.38642 2.57116 8.17317 3.0737C6.95991 3.57625 5.85752 4.31285 4.92893 5.24143C3.05357 7.1168 2 9.66034 2 12.3125C2 14.9647 3.05357 17.5082 4.92893 19.3836C5.85752 20.3122 6.95991 21.0487 8.17317 21.5513C9.38642 22.0538 10.6868 22.3125 12 22.3125C14.6522 22.3125 17.1957 21.2589 19.0711 19.3836C20.9464 17.5082 22 14.9647 22 12.3125C22 10.9993 21.7413 9.69892 21.2388 8.48567C20.7362 7.27241 19.9997 6.17002 19.0711 5.24143C18.1425 4.31285 17.0401 3.57625 15.8268 3.0737C14.6136 2.57116 13.3132 2.3125 12 2.3125ZM12 4.3125C16.41 4.3125 20 7.9025 20 12.3125C20 16.7225 16.41 20.3125 12 20.3125C7.59 20.3125 4 16.7225 4 12.3125C4 7.9025 7.59 4.3125 12 4.3125ZM9 9.3125V15.3125H15V9.3125" fill="white" />
                            </svg>
                            } >
                                {t('Stop')}
                            </Button>
                        </div>
                        {snooze > 0 && <div className="btn-div snooze-btn-div">
                            <Button onClick={snoozeAlarm} variant="contained" startIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.29 3.56262L5.16 7.03262L4 5.65262L8.14 2.18262L9.29 3.56262ZM22 5.66262L20.84 7.04262L16.7 3.56262L17.86 2.18262L22 5.66262ZM13 4.31262C15.1217 4.31262 17.1566 5.15547 18.6569 6.65576C20.1571 8.15605 21 10.1909 21 12.3126C21 14.4343 20.1571 16.4692 18.6569 17.9695C17.1566 19.4698 15.1217 20.3126 13 20.3126C10.8783 20.3126 8.84344 19.4698 7.34315 17.9695C5.84285 16.4692 5 14.4343 5 12.3126C5 10.1909 5.84285 8.15605 7.34315 6.65576C8.84344 5.15547 10.8783 4.31262 13 4.31262ZM13 6.31262C11.4087 6.31262 9.88258 6.94476 8.75736 8.06998C7.63214 9.1952 7 10.7213 7 12.3126C7 13.9039 7.63214 15.43 8.75736 16.5553C9.88258 17.6805 11.4087 18.3126 13 18.3126C14.5913 18.3126 16.1174 17.6805 17.2426 16.5553C18.3679 15.43 19 13.9039 19 12.3126C19 10.7213 18.3679 9.1952 17.2426 8.06998C16.1174 6.94476 14.5913 6.31262 13 6.31262ZM12 7.81262H13.5V12.3426L16.72 13.8126L16.1 15.1726L12 13.3126V7.81262ZM1 14.3126C1 11.8126 2.13 9.61262 3.91 8.14262C3.33 9.41262 3 10.8126 3 12.3126L3.06 13.4426L3 14.3126C3 16.5926 4.27 18.5726 6.14 19.5926C7.44 20.8126 9.07 21.7026 10.89 22.0926C10.28 22.2326 9.65 22.3126 9 22.3126C6.87827 22.3126 4.84344 21.4698 3.34315 19.9695C1.84285 18.4692 1 16.4343 1 14.3126Z" fill="#56A269" />
                            </svg>
                            }   >
                                {t('key_snooze')}
                            </Button>
                        </div>}
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )

}


