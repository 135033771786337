
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { useTranslations } from 'next-intl';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { experimentalStyled as styled } from '@mui/material/styles';
const _expandIcon = styled(props => (
    <div {...props}>

        <div className="n">
            <div className='circle'>
                <KeyboardArrowDownIcon />
            </div>
        </div>
        <div className="y">
            <div className='circle'>
                <KeyboardArrowRightIcon />

            </div>

        </div>
    </div>
))`
    & > .y {
      display: block;
    }
    & > .n {
      display: none;
    }
    .Mui-expanded & > .n {
      display: block;
    }
    .Mui-expanded & > .y {
      display: none;
    }
  `;
export default function Main({ faq, from = 'alarm', lang_code }) {
    const faqs = JSON.parse(faq);


    const t = useTranslations();

    return (<>
        <div className="my-container">
            <h1 className="alarms-title faq-title">{t('FAQ')}</h1>
            {/*<p className="inner-title">{t('Here you can find answers to your questions')}</p>*/}
            <div className="faq-container">
                {faqs && faqs.length > 0 && faqs.slice(0).reverse().map((v, i) => {

                    if (v.content && v.content['0']?.title) {
                        return (
                            <Accordion key={i}>
                                <AccordionSummary
                                    expandIcon={<_expandIcon />}
                                    aria-controls={`panel1a_${i}`}
                                    id={`panel1_${i}`}
                                >
                                    <Typography>{v?.content['0']?.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails id={`pandel1_${i}`}>
                                    <Typography dangerouslySetInnerHTML={{
                                        __html: v?.content['0']?.about
                                            .replace('timertone.com', `<a name="timertone" href="//${lang_code}.timertone.${process.env.NEXT_PUBLIC_DOMAIN_SUFFIX}/dashboard/${from}">timertone.com</a>`)
                                            .replace('TimerTone.com', `<a name="timertone" href="//${lang_code}.timertone.${process.env.NEXT_PUBLIC_DOMAIN_SUFFIX}/dashboard/${from}">TimerTone.com</a>`)
                                    }}>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                    }
                })}
            </div>
        </div>
    </>)
}